class LocalStorageManager {
    constructor(key) {
        this.key        = key;
        this.storedData = {};

        try {
            this.storedData = JSON.parse(window.localStorage.getItem(key)) || {};
        } catch(err) {
            this.storedData = {};
        }
    }

    getLevel() {
        return this.storedData.storedLevel || 0;
    }

    getDeathCounter() {
        return this.storedData.deathCounter || 0;
    }

    setLevel(storedLevel) {
        this.storedData.storedLevel = storedLevel;
        this.saveData();
    }

    setDeathCounter(deathCounter) {
        this.storedData.deathCounter = deathCounter;
        this.saveData();
    }

    saveData() {
        window.localStorage.setItem(this.key, JSON.stringify(this.storedData));
    }

    clearData() {
        this.storedData = {};
        window.localStorage.removeItem(this.key);
    }

    getTime() {
        return this.storedData.time || 0;
    }

    addTime(ts) {
        if (!this.storedData.time) {
            this.storedData.time = ts;
        } else {
            this.storedData.time += ts;
        }

        this.saveData();
    }

    addScore(collected, total) {
        if (!this.storedData.collected) {
            this.storedData.collected = collected;
        } else {
            this.storedData.collected += collected;
        }

        if (!this.storedData.total) {
            this.storedData.total = total;
        } else {
            this.storedData.total += total;
        }

        this.saveData();
    }

    getCollectedSlices() {
        return {
            collected: this.storedData.collected,
            total:     this.storedData.total,
        }
    }
}

export default LocalStorageManager;