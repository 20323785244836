import Phaser        from 'phaser';
import KsonGame      from './KsonGame';
import StartScene    from './StartScene';
import PlayScene     from './PlayScene';
import EndScene      from './EndScene';
import GLOBAL_CONFIG from './globalConfig';
import initMapLoader from './mapLoader';

const DEBUG = false;

const config = {
  type:            Phaser.AUTO,
  width:           GLOBAL_CONFIG.VIEWPORT_WIDTH,
  height:          GLOBAL_CONFIG.VIEWPORT_HEIGHT,
  autoCenter:      false,
  backgroundColor: 0xffffff,
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: GLOBAL_CONFIG.WORLD_GRAVITY },
      debug:   DEBUG,
    },
  },
  scale: {
      mode: Phaser.Scale.FIT,
      parent:     'game-container',
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: GLOBAL_CONFIG.VIEWPORT_WIDTH,
      height: GLOBAL_CONFIG.VIEWPORT_HEIGHT
  },
  scene: [StartScene, PlayScene, EndScene],
}

new KsonGame(config);

initMapLoader();
