const TILE_SIZE = 40;
const WORLD_HEIGHT = 15 * TILE_SIZE;
const ACCELERATION = 400;


export default {
    TILE_SIZE,
    WORLD_HEIGHT,
    WORLD_WIDTH: 110 * TILE_SIZE,
    VIEWPORT_WIDTH: 25 * TILE_SIZE,
    VIEWPORT_HEIGHT: WORLD_HEIGHT,
    JUMP_HEIGHT: 550,
    ACCELERATION,
    AIR_ACCELERATION: ACCELERATION * 0.8,
    PLAYER_X: 0,
    PLAYER_Y: WORLD_HEIGHT - TILE_SIZE - 40,
    MAX_JUMPS: 2,
    BG_OFFSET: 0,
    WORLD_GRAVITY: 1500,
    FRICTION: 5500,
    PINEAPPLE_ACCELERATION: 420,
    PINEAPPLE_VISIBLE_TIME: 5000,
    PINEAPPLES: ['pineapple-ninja', 'pineapple-mosaic'],
    DEATH_SOUNDS: 8,
    LOCAL_STORAGE_KEY: 'KSON_GAME',
    BACKGROUNDS: [
        {
            layersPrefix:   'japan',
            fixedLayers:    [1, 3, 6, 7, 8, 9],
            offsets:        [-1, -1, 0.2, -1, -1, 0.5, -1, -1, -1],
            numberOfLayers: 9,
        },
        {
            layersPrefix:   'night',
            fixedLayers:    [1, 2, 3, 4, 5, 6, 7, 8, 9],
            offsets:        [-1, -1, -1, -1, -1, -1, 1.4, -1, 0.9],
            numberOfLayers: 9,
        },
    ],
    FONT: 'Hogfish',
    AUX_FONT: 'Chihaya Jun',
    DESTRUCTIBLE_PLATFORM_TIME: 1000,
    MESSAGES: [
        'test message',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        'Quisque consequat libero vel neque pellentesque, vel dignissim metus porta.',
    ],
    PRIMARY_COLOUR: '#fdbb2c',
    SECONDARY_COLOUR: '#391650',
    FONT_SIZE: '20px',
};