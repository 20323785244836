import GLOBAL_CONFIG from './globalConfig';

class EndScene extends Phaser.Scene {
    constructor () {
      super({key: 'EndScene'});

      this.cursors   = [];
    }

    preload() {
      this.load.image('end-screen', '../assets/img/kson-end.png');
      this.load.image('end-screen-2', '../assets/img/kson-end-2.png');
      this.load.image('kson-heart', '../assets/img/kson-heart.png');
    }

    create(config) {
      this.storedDataManager = config.storedDataManager;

      this.initEndScreen();
      this.initKeyboard();
    }

    initEndScreen() {
      this.cameras.main.fadeIn(100, 255, 255, 255);

      const time                      = this.storedDataManager.getTime();
      const slices                    = this.storedDataManager.getCollectedSlices();
      const deathCounter              = this.storedDataManager.getDeathCounter();
      const collectedSlicesPercentage = Phaser.Math.FloorTo((slices.collected/slices.total) * 100);

      this.layer2 = this.add.image(0, 0, 'end-screen-2').setOrigin(0, 0);
      this.layer1 = this.add.image(0, 0, 'end-screen').setOrigin(0, 0);

      this.layer2.visible = false;
      this.layer1.visible = true;

      setInterval(() => {
        if (this.layer1.visible) {
          this.layer1.visible = false;
          this.layer2.visible = true;
        } else {
          this.layer1.visible = true;
          this.layer2.visible = false;
        }
      }, 500);
      
      const TEXT_LEFT = 105;
      const TEXT_TOP  = 370;

      this.add.text(TEXT_LEFT, TEXT_TOP, 'SLICES:', {
        fontSize:   GLOBAL_CONFIG.FONT_SIZE,
        fill:       GLOBAL_CONFIG.PRIMARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });


      const collectedText = this.add.text(TEXT_LEFT, TEXT_TOP, `                 ${collectedSlicesPercentage}% (${slices.collected}/${slices.total})`, {
        fontSize:   GLOBAL_CONFIG.FONT_SIZE,
        fill:       GLOBAL_CONFIG.SECONDARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });


      if (collectedSlicesPercentage === 100) {
        this.add.image(collectedText.x + collectedText.width + 45, collectedText.y + 10, 'kson-heart');
      }

      this.add.text(TEXT_LEFT, TEXT_TOP + 24, 'TIME:', {
        fontSize:   GLOBAL_CONFIG.FONT_SIZE,
        fill:       GLOBAL_CONFIG.PRIMARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });

      this.add.text(TEXT_LEFT, TEXT_TOP + 24, `                 ${this.msToTime(time)}`, {
        fontSize:   GLOBAL_CONFIG.FONT_SIZE,
        fill:       GLOBAL_CONFIG.SECONDARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });

      this.add.text(TEXT_LEFT, TEXT_TOP + 48, `DEATHS:`, {
        fontSize:   GLOBAL_CONFIG.FONT_SIZE,
        fill:       GLOBAL_CONFIG.PRIMARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });

      this.add.text(TEXT_LEFT, TEXT_TOP + 48, `                 ${deathCounter}`, {
        fontSize:   GLOBAL_CONFIG.FONT_SIZE,
        fill:       GLOBAL_CONFIG.SECONDARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });

      this.storedDataManager.clearData();
    }

    msToTime(duration) {
      let milliseconds = Math.floor((duration % 1000) / 100),
          seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = (hours < 10) ? '0' + hours : hours;
      minutes = (minutes < 10) ?'0' + minutes : minutes;
      seconds = (seconds < 10) ? '0' + seconds : seconds;

      return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
    }

    initKeyboard() {
      //  Input Events
      this.cursors = this.input.keyboard.addKeys(
        {
          space: Phaser.Input.Keyboard.KeyCodes.SPACE,
        });
    }

    update() {
      if (this.cursors.space.isDown) {
        // this.scene.start('StartScene');
      }
    }
}

export default EndScene;