window.EXTERNAL_MAP = '';


function initMapLoader() {
    const mapLoaderInput = document.querySelector('#map-loader');

    if (!mapLoaderInput) { return; }

    mapLoaderInput.addEventListener('change', (evt) => {
        const file = evt.target.files[0];
        if (file) {
          var reader = new FileReader();
          reader.readAsText(file, 'UTF-8');
          reader.onload = function (evt) {
            window.EXTERNAL_MAP = evt.target.result;
          }
        }
      });
}

export default initMapLoader;