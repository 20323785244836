import Kson          from './Player';
import GLOBAL_CONFIG from './globalConfig';

class PlayScene extends Phaser.Scene {
    constructor () {
      super({key: 'PlayScene'});

      // Objects.
      this.player = null;
      this.pineapples = [];
      this.cursors = [];
      this.backgroundSprites = [];
      this.pineapplesTweens = [];
      this.platformsTimers = {};

      // Text.
      this.collectedSlices = 0;
      this.scoreText       = '';
      this.messages        = [];

      // Sounds.
      this.pizzaEatingSound = null;
      this.painSound = null;
      this.jumpSound = null;
      this.jumpSound2 = null;
      this.jumpSound3 = null;

      // Logic.
      this.gameOver           = false;
      this.gameOverTimeoutSet = false;
      this.currentLevel = 0;
      this.levelLoading = false;
      this.totalSlices = 0;

      // Time.
      this.startTime = null;
    }

    preload() {
      this.load.image('pineapple', '../assets/img/pineapple.png')
      this.load.image('pineapple-ninja', '../assets/img/pineapple-ninja.png');
      this.load.image('pineapple-mosaic', '../assets/img/pineapple-mosaic.png');
      this.load.image('pizza', '../assets/img/pizza.png');
      this.load.image('pizza-golden', '../assets/img/pizza-golden.png');


      this.load.spritesheet('player', '../assets/img/kson-sprite-new.png', {
        frameWidth: 60,
        frameHeight: 94,
      });


      this.load.audio('eating', '../assets/sounds/eating.mp3');
      this.load.audio('jump', '../assets/sounds/jump.mp3');
      this.load.audio('jump3', '../assets/sounds/jump3.mp3');

      let level = '../assets/maps/map.json';

      if (window.EXTERNAL_MAP) {
        level = JSON.parse(window.EXTERNAL_MAP);
      }

      this.load.image('tiles', '../assets/maps/tiles.png')
      this.load.tilemapTiledJSON('map', level);

      //Death sounds.
      for (let i = 1; i <= GLOBAL_CONFIG.DEATH_SOUNDS; i++) {
        this.load.audio(`death${i}`, `assets/sounds/death${i}.mp3`);
      }

      // Preload bgs.
      GLOBAL_CONFIG.BACKGROUNDS.forEach(({numberOfLayers, layersPrefix}) => {
          for (let i = 1; i <= numberOfLayers; i++) {
            this.load.image(`bg-sprite-${layersPrefix}-${i}`, `assets/img/${layersPrefix}-layers/lay${i}.png`);
          }
      });

    }

    create(config) {
      this.levelLoading       = false;
      this.storedDataManager  = config.storedDataManager;
      this.currentLevel       = config.continue ? this.storedDataManager.getLevel() : 0;
      this.selectedBackground = GLOBAL_CONFIG.BACKGROUNDS[0];
      this.deathCounter       = config.continue ? this.storedDataManager.getDeathCounter() : 0;

      this.setupScene();
      this.initSounds();

      this.initPlayer();
      this.initPlatforms();
      this.initPhysics();
      this.initCamera();
      this.initPizzaProgress();

      this.cameras.main.fadeIn(100, 255, 255, 255);
      this.storedDataManager.addTime(0);
    }

    initCamera() {
      this.cameras.main.setBounds(0, 0, GLOBAL_CONFIG.WORLD_WIDTH, GLOBAL_CONFIG.WORLD_HEIGHT);
      this.physics.world.setBounds(0, 0, GLOBAL_CONFIG.WORLD_WIDTH, GLOBAL_CONFIG.WORLD_HEIGHT);

      this.cameras.main.setViewport(0, 0, GLOBAL_CONFIG.VIEWPORT_WIDTH, GLOBAL_CONFIG.VIEWPORT_HEIGHT);
      this.cameras.main.startFollow(this.player.sprite);
    }

    initPizzaProgress() {
      const X = 10;
      const Y = 10;

      this.pizzaProgress   = this.add.image(X, Y, 'pizza').setOrigin(0,0).setScrollFactor(0);
      this.pizzaProgressBg = this.add.image(X, Y, 'pizza').setOrigin(0,0).setScrollFactor(0).setAlpha(.3);
      this.pizzaCompleted = this.add.image(X, Y, 'pizza-golden').setOrigin(0,0).setScrollFactor(0).setAlpha(0);

      this.pizzaProgress.setDepth(10);
      this.pizzaProgressBg.setDepth(10);
      this.pizzaCompleted.setDepth(10);

      this.pizzaProgressMaskShape = this.make.graphics();
      this.pizzaProgressMaskShape.setX(X);
      this.pizzaProgressMaskShape.setY(Y);

      this.pizzaProgressMaskShape.fillStyle(0xffffff, 1);
      this.pizzaProgressMaskShape.fillRect(0, 0, this.pizzaProgress.width, this.pizzaProgress.height);
      this.pizzaProgressMaskShape.setScrollFactor(0);
      this.pizzaProgressMaskShape.scaleY = 0;

      this.pizzaProgressMask = new Phaser.Display.Masks.GeometryMask(this, this.pizzaProgressMaskShape);
      this.pizzaProgress.setMask(this.pizzaProgressMask);
    }

    updatePizzaProgress(progress) {
      this.pizzaProgressMaskShape.setScale(1, progress);

      if (progress === 1) {
        this.pizzaCompleted.setAlpha(1);
      } else {
        this.pizzaCompleted.setAlpha(0);
      }
    }

    initPlayer() {
      this.player = new Kson(this, GLOBAL_CONFIG.PLAYER_X, GLOBAL_CONFIG.PLAYER_Y);
    }

    initSounds() {
      this.pizzaEatingSound = this.sound.add('eating');
      this.jumpSound        = this.sound.add('jump');
      this.jumpSound3       = this.sound.add('jump3');

      this.deathSounds = [];

      for (let i = 1; i <= GLOBAL_CONFIG.DEATH_SOUNDS; i++) {
        const sound = this.sound.add(`death${i}`);
        this.deathSounds.push(sound);
      }
    }

    setupScene() {
      this.physics.world.setBounds(0, 0, GLOBAL_CONFIG.WORLD_WIDTH, GLOBAL_CONFIG.WORLD_HEIGHT, true, true, false, true);

      this.clearBackground();
      this.setBackground();
    }

    clearBackground() {
      this.backgroundSprites.forEach(sprite => {
        sprite.destroy();
      });

      this.backgroundSprites = [];
    }

    setBackground() {
      for (let i = 1; i <= this.selectedBackground.numberOfLayers; i++) {
        const spriteId = `bg-sprite-${this.selectedBackground.layersPrefix}-${i}`;
        const sprite   = this.add.tileSprite(0, 0, GLOBAL_CONFIG.WORLD_WIDTH, GLOBAL_CONFIG.VIEWPORT_HEIGHT, spriteId)
                               .setOrigin(0);

        if (this.selectedBackground.fixedLayers.indexOf(i) !== -1) {
          sprite.setScrollFactor(0, 1);
        } else {
          sprite.setScrollFactor(1, 1);
        }
        this.backgroundSprites.push(sprite);
      }
    }

    setTime() {
      if (!this.startTime) {
        this.startTime = new Date().getTime();
      }

      const timeDelta = new Date().getTime() - this.startTime;
      this.storedDataManager.addTime(timeDelta);

      this.startTime = new Date().getTime();
    }

    sceneCleanup() {
      if (this.mainLayer && this.mainLayer.setTileIndexCallback) {
        this.mainLayer.setTileIndexCallback(4, null, this);
        this.mainLayer.setTileIndexCallback(5, null, this);
      }

      if (this.mainLayerCollider && this.mainLayerCollider.destroy) {
        this.mainLayerCollider.destroy();
      }

      if (this.spikes) {
        this.spikes.clear(true, true);
      } else {
        this.spikes = this.physics.add.group({
          allowGravity: false,
        });
      }

      if (this.platforms) {
        this.platforms.clear(true, true);
      } else {
        this.platforms = this.physics.add.group({
          allowGravity: false,
        });
      }

      if (this.destructiblePlatforms) {
        this.destructiblePlatforms.clear(true, true);
      } else {
        this.destructiblePlatforms = this.physics.add.group({
          allowGravity: false,
        });
      }

      if (this.map && this.map.destroy) {
        this.map.destroy();
      }

      if (this.messages && this.messages.length) {
        this.messages.forEach(message => {
          message.destroy();
        });

        this.messages = [];
      }

      this.totalSlices = 0;
    }

    initPlatforms() {
      this.sceneCleanup();

      this.map = this.make.tilemap({ key: 'map' });

      this.cameras.main.fadeIn(100, 255, 255, 255);

      const layerName = this.map.layers[this.currentLevel].name;
      const tiles     = this.map.addTilesetImage('tiles');

      this.mainLayer = this.map.createLayer(layerName, tiles);

      let textTileCounter = 0;

      const TILES_MAP = {
        1: 'INVISIBLE_PLATFORM',
        2: 'PLATFORM',
        3: 'SPIKE',
        4: 'SLICE',
        6: 'DESTRUCTIBLE_PLATFORM',
        7: 'TEXT',
        8: 'PLATFORM'
      };

      // Add collision for spikes.
      this.mainLayer.tilemap.layer.data.forEach(i => {
        i.forEach(tile => {
            const index = tile.index;
            const X     = tile.x * GLOBAL_CONFIG.TILE_SIZE;
            const Y     = tile.y * GLOBAL_CONFIG.TILE_SIZE;

            if(TILES_MAP[index] === 'INVISIBLE_PLATFORM') {
              tile.setAlpha(0);

              const platform = this.physics.add.sprite(X, Y, null, null).setOrigin(0, 0).setVisible(false);
              platform.body.setOffset(0, 15);
              platform.body.width = 40;
              platform.body.height = 25;
              platform.body.pushable = false;
              this.platforms.add(platform);
            }

            if (TILES_MAP[index] === 'PLATFORM') {
              const platform = this.physics.add.sprite(X, Y, null, null).setOrigin(0, 0).setVisible(false);
              platform.body.width = 40;
              platform.body.height = 28;
              platform.body.pushable = false;
              this.platforms.add(platform);
            }

            if (TILES_MAP[index] === 'SLICE') {
              this.totalSlices += 1;
            }

            if(TILES_MAP[index] === 'SPIKE') {
              const spikes = this.physics.add.sprite(X, Y, null, null).setOrigin(0, 0).setVisible(false);
              spikes.body.setOffset(0, 20);
              spikes.body.width = 40;
              spikes.body.height = 20;
              spikes.body.pushable = false;
              this.spikes.add(spikes);
            }

            if(TILES_MAP[index] === 'DESTRUCTIBLE_PLATFORM') {
              const platform = this.physics.add.sprite(X, Y, null, null).setOrigin(0, 0).setVisible(false);
              platform.body.width = 40;
              platform.body.height = 29;
              platform.body.pushable = false;
              platform.tileX = tile.x;
              platform.tileY = tile.y;
              platform.tileId = `tile-id-${tile.x*tile.y}`;
              this.destructiblePlatforms.add(platform);
            }

            if(TILES_MAP[index] === 'TEXT') {
              tile.setAlpha(0);

              const message = this.add.text(X, Y, GLOBAL_CONFIG.MESSAGES[textTileCounter], {
                fontSize: '14px',
                fill: '#000',
                fontFamily: GLOBAL_CONFIG.FONT,
              });

              this.messages.push(message);

              textTileCounter++;
            }
        });
      });

      this.mainLayerCollider = this.physics.add.collider(this.player.sprite, this.mainLayer);

      this.mainLayer.setTileIndexCallback(4, this.collectPizza, this);
      this.mainLayer.setTileIndexCallback([9, 10, 11, 12], this.nextLevel, this);
    }

    initPhysics() {
      this.pineapples = this.physics.add.group({
        allowGravity: false,
      });

      this.physics.add.collider(this.player.sprite, this.pineapples, this.hit, null, this);
      this.physics.add.collider(this.player.sprite, this.spikes, this.hit, null, this);

      this.physics.add.collider(this.player.sprite, this.platforms);
      this.physics.add.collider(
        this.player.sprite,
        this.destructiblePlatforms,
        this.handleDestructiblePlatforms,
        null,
        this,
      );
    }

    despawnPineapples() {
      this.pineapples.clear(true, true);
      this.pineapplesTweens.forEach(tween => {
        tween.remove();
      });

      this.pineapplesTweens = [];
    }

    handleDestructiblePlatforms(player, platform) {
      if (player.body.blocked.down) {
        if (this.platformsTimers[platform.tileId]) { return; }
        const tile = this.map.getTileAt(platform.tileX, platform.tileY);
        tile.alpha = .5;

        this.platformsTimers[platform.tileId] = this.time.addEvent({
          delay:    GLOBAL_CONFIG.DESTRUCTIBLE_PLATFORM_TIME,
          callback: () => {
            this.platformsTimers[platform.tileId] = false;
            this.map.removeTileAt(platform.tileX, platform.tileY);
            platform.destroy();
          },
          callbackScope: this,
          loop: false
        });
      }
    }

    resetScene() {
      this.player.sprite.x = GLOBAL_CONFIG.PLAYER_X;
      this.player.sprite.y = GLOBAL_CONFIG.PLAYER_Y;

      this.player.sprite.clearTint();
      this.player.isDead = false;

      this.despawnPineapples();

      this.initPlatforms();

      this.collectedSlices = 0;
      this.updatePizzaProgress(0);

      this.physics.resume();

      this.setTime();

      for (const id in this.platformsTimers) {
        if (this.platformsTimers[id].remove) {
          this.platformsTimers[id].remove();
        }
      }

      this.platformsTimers = {};
    }

    update() {
      this.player.update();

      this.backgroundSprites.forEach((sprite, index) => {
        if (this.selectedBackground.fixedLayers.indexOf(index + 1) !== -1) {
          let offsetX = 0.7;

          if (this.selectedBackground.offsets[index] !== -1) {
            offsetX = this.selectedBackground.offsets[index];
          }

          sprite.setTilePosition(this.cameras.main.scrollX * offsetX, GLOBAL_CONFIG.BG_OFFSET);
        } else {
          const offsetX = index * 0.2;
          sprite.setTilePosition(sprite.tilePositionX + offsetX, GLOBAL_CONFIG.BG_OFFSET);
        }
      });
    }

    collectPizza(sprite, tile) {
      if (sprite.name !== 'player') { return; }

      this.collectedSlices += 1;
      // this.updateCollectedSlicesText();

      if (this.pizzaEatingSound) { this.pizzaEatingSound.play(); }

      tile.visible = false;

      this.mainLayer.removeTileAt(tile.x, tile.y);
      this.spawnPineapple();

      const progress = (this.collectedSlices / this.totalSlices);
      this.updatePizzaProgress(progress);
    }

    updateCollectedSlicesText() {
      this.scoreText.setText(`Collected slices: ${this.collectedSlices}/${this.totalSlices}`);
    }

    spawnPineapple() {
      const spawnOffset = 350;

      const bottomLocations = [3, 4, 5, 6, 6, 6, 7, 7];
      const topLocations    = [0, 1, 2, 6, 6, 7, 7, 7];

      const spawnLocations = [
        {x: this.player.sprite.x - spawnOffset, y: this.player.sprite.y - spawnOffset, angle: -45},
        {x: this.player.sprite.x + spawnOffset, y: this.player.sprite.y - spawnOffset, angle: 45},
        {x: this.player.sprite.x, y: this.player.sprite.y - spawnOffset, angle: 0},
        {x: this.player.sprite.x - spawnOffset, y: this.player.sprite.y + spawnOffset, angle: -135},
        {x: this.player.sprite.x + spawnOffset, y: this.player.sprite.y + spawnOffset, angle: 135},
        {x: this.player.sprite.x, y: this.player.sprite.y + spawnOffset, angle: 180},
        {x: this.player.sprite.x - spawnOffset, y: this.player.sprite.y, angle: -90},
        {x: this.player.sprite.x + spawnOffset, y: this.player.sprite.y, angle: 90},
      ];

      const skipTop    = ((this.player.sprite.y - spawnOffset) < 0);
      const skipBottom = ((this.player.sprite.y + spawnOffset) > GLOBAL_CONFIG.WORLD_HEIGHT);

      let _locationIndex = Math.round(Math.random() * 7);

      if (skipTop && !skipBottom) {
        _locationIndex = bottomLocations[_locationIndex];
      }

      if (skipBottom && !skipTop) {
        _locationIndex = topLocations[_locationIndex];
      }

      const pineappleType = GLOBAL_CONFIG.PINEAPPLES[Math.round(Math.random())];

      const spawnLocation = spawnLocations[_locationIndex];
      const pineapple = this.pineapples.create(spawnLocation.x, spawnLocation.y, pineappleType);

      pineapple.alpha = 0;
      pineapple.setTintFill(0xffffff);
      pineapple.setAngle(spawnLocation.angle);
      pineapple.setSize(10, 10);
      pineapple.setName('pineapple');

      const alphaTween = this.add.tween({
        targets: pineapple,
        ease: 'Sine.easeIn',
        duration: 200,
        delay: 0,
        alpha: {
          getStart: () => 0,
          getEnd: () => 1
        },
        onComplete: () => {
          pineapple.clearTint();
          if(!pineapple.body) { return; }

          this.physics.accelerateTo(
            pineapple,
            this.player.sprite.x,
            this.player.sprite.y,
            GLOBAL_CONFIG.PINEAPPLE_ACCELERATION
          );
        }
      });

      const angleTween = this.add.tween({
        targets: pineapple,
        ease: 'Sine.easeIn',
        duration: 100,
        delay: 0,
        loop: -1,
        angle: {
          getStart: () => spawnLocation.angle - 2,
          getEnd: () => spawnLocation.angle + 2
        },
      });

      this.pineapplesTweens.push(alphaTween);
      this.pineapplesTweens.push(angleTween);

      this.time.addEvent({
          delay: GLOBAL_CONFIG.PINEAPPLE_VISIBLE_TIME,                // ms
          callback: () => {
            pineapple.destroy();
            alphaTween.remove();
            angleTween.remove();
          },
          callbackScope: this,
          loop: false
      });
    }

    endGame() {
      this.startTime = null;
      this.currentLevel = 0;
      this.collectedSlices = 0;

      this.scene.start('EndScene', {
        storedDataManager: this.storedDataManager
      });
    }

    nextLevel(sprite) {
      if (sprite.name !== 'player') { return; }

      if (!this.levelLoading) {
        this.despawnPineapples();

        this.cameras.main.fadeOut(100, 255, 255, 255);

        this.time.addEvent({
            delay: 110,
            callback: () => {
              this.currentLevel += 1;
              this.storedDataManager.setLevel(this.currentLevel);
              this.levelLoading = false;
              this.storedDataManager.addScore(this.collectedSlices, this.totalSlices);

              this.setTime();

              if (this.map.layers && this.map.layers.length <=this.currentLevel) {
                this.endGame();
              } else {
                if (this.currentLevel === 3) {
                  this.clearBackground();
                  this.selectedBackground = GLOBAL_CONFIG.BACKGROUNDS[1];
                  this.setBackground();
                }

                this.resetScene();
              }
            },
            callbackScope: this,
            loop: false
        });

        this.levelLoading = true;
      }
    }

    hit() {

      if (this.player.dashing) { return; }

      this.player.isDead = true;
      this.player.sprite.setTint(0xff0000);
      this.gameOver = true;

      this.physics.pause();

      if (this.gameOver) {
        if (!this.gameOverTimeoutSet) {
          this.cameras.main.fadeOut(100, 255, 255, 255);
          this.deathSounds[Math.round(Math.random() * 7)].play();

          this.deathCounter += 1;
          this.storedDataManager.setDeathCounter(this.deathCounter);

          this.time.addEvent({
              delay: 100,                // ms
              callback: () => {
                this.resetScene();
                this.cameras.main.fadeIn(100, 255, 255, 255);
                this.gameOver = false;
                this.gameOverTimeoutSet = false;
              },
              callbackScope: this,
              loop: false
          });
        }

        this.gameOverTimeoutSet = true;
      }
    }
}

export default PlayScene;