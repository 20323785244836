import GLOBAL_CONFIG        from './globalConfig';
import LocalStorageManager from './LocalStorageManager';

class StartScene extends Phaser.Scene {
    constructor () {
      super({key: 'StartScene'});

      this.cursors   = [];
      this.visibleLayer = 1;
      this.initialized = false;
      this.soundEnabled = true;
      this.loading = true;
      this.storedDataManager = new LocalStorageManager(GLOBAL_CONFIG.LOCAL_STORAGE_KEY);
      this.hasContinue = this.storedDataManager.getLevel();
      this.step = 0;
    }

    preload() {
      this.load.image('title-screen', '../assets/img/kson-title.png');
      this.load.image('title-screen-2', '../assets/img/kson-title-2.png');

      this.load.image('hints', '../assets/img/hints.png');
      this.load.image('hints-2', '../assets/img/hints-2.png');

      this.load.audio('bgm', '../assets/bgms/のんびりサラダハウス.mp3');
      this.load.audio('new-game', '../assets/sounds/new_game.mp3');
    }

    create() {
      this.initTitleScreen();
      this.initKeyboard();
      this.initSounds();
      this.cameras.main.fadeIn(100, 255, 255, 255);
    }

    initSounds() {
      // BGM already set.
      if (this.initialized) { return; }
      this.bgm = this.sound.add('bgm', {volume: 0});
      this.tweens.add({
        targets:  this.bgm,
        volume:   0.13,
        duration: 1000
      });
      this.bgm.volume = 0;
      this.bgm.loop   = true;
      this.bgm.resume();
      this.bgm.play();

      this.newGameSound = this.sound.add('new-game');

      this.time.addEvent({
          delay: 1000,
          callback: () => {
            this.loading = false;
          },
          callbackScope: this,
          loop: false
      });
    }

    toggleBgm() {
      this.soundEnabled = !this.soundEnabled;

      if (this.soundEnabled) {
        this.bgm.resume();
        this.toggleSoundText.text = '       Toggle BGM: ON';
      } else {
        this.bgm.pause();
        this.toggleSoundText.text = '       Toggle BGM: OFF';
      }
    }

    initTitleScreen() {
      const TEXT_LEFT = 5;

      this.layer2 = this.add.image(0, 0, 'title-screen-2').setOrigin(0, 0);
      this.layer1 = this.add.image(0, 0, 'title-screen').setOrigin(0, 0);

      this.layer2.visible = false;
      this.layer1.visible = true;

      this.add.text(TEXT_LEFT, 574, 'BGM:', {
        fontSize:   '14px',
        fill:       GLOBAL_CONFIG.PRIMARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });

      this.add.text(TEXT_LEFT, 574, '       shimtone -', {
        fontSize:   '14px',
        fill:       GLOBAL_CONFIG.SECONDARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });

      this.add.text(TEXT_LEFT, 574, '         のんびりサラダハウス', {
        fontSize:   '24px',
        fill:       GLOBAL_CONFIG.SECONDARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.AUX_FONT,
      });

      this.add.text(TEXT_LEFT, 554, '[T]', {
        fontSize:   '14px',
        fill:       GLOBAL_CONFIG.PRIMARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });

      this.toggleSoundText = this.add.text(TEXT_LEFT, 554, '       Toggle BGM: ON', {
        fontSize:   '14px',
        fill:       GLOBAL_CONFIG.SECONDARY_COLOUR,
        fontFamily: GLOBAL_CONFIG.FONT,
      });

      if (this.initialized) { return; }

      const MENU_LEFT = 108;
      const MENU_TOP  = 280;

      this.time.addEvent({
          delay:    1000,
          callback: () => {
            const startGameTextKey = this.add.text(MENU_LEFT, MENU_TOP, '[SPACE]', {
              fontSize:   GLOBAL_CONFIG.FONT_SIZE,
              fill:       GLOBAL_CONFIG.PRIMARY_COLOUR,
              fontFamily: GLOBAL_CONFIG.FONT,
            });

            const startGameText = this.add.text(MENU_LEFT, MENU_TOP, '               START', {
              fontSize:   GLOBAL_CONFIG.FONT_SIZE,
              fill:       GLOBAL_CONFIG.SECONDARY_COLOUR,
              fontFamily: GLOBAL_CONFIG.FONT,
            });

            startGameText.alpha = 0;

            this.tweens.add({
              targets: [startGameTextKey, startGameText],
              alpha: 1,
              duration: 300,
              repeat: 0,
            });


            if (this.hasContinue) {
              const continueTextKey = this.add.text(MENU_LEFT, MENU_TOP + 24, '[C]', {
                fontSize:   GLOBAL_CONFIG.FONT_SIZE,
                fill:       GLOBAL_CONFIG.PRIMARY_COLOUR,
                fontFamily: GLOBAL_CONFIG.FONT,
              });

              const continueText = this.add.text(MENU_LEFT, MENU_TOP + 24, '               CONTINUE', {
                fontSize:   GLOBAL_CONFIG.FONT_SIZE,
                fill:       GLOBAL_CONFIG.SECONDARY_COLOUR,
                fontFamily: GLOBAL_CONFIG.FONT,
              });

              continueText.alpha = 0;

              this.tweens.add({
                targets: [continueTextKey, continueText],
                alpha: 1,
                duration: 300,
                repeat: 0,
              });
            }
          },
          callbackScope: this,
          loop: false
      });

      setInterval(() => {
        if (this.layer1.visible) {
          this.layer1.visible = false;
          this.layer2.visible = true;
        } else {
          this.layer1.visible = true;
          this.layer2.visible = false;
        }
      }, 500);
    }

    initKeyboard() {
      //  Input Events
      this.cursors = this.input.keyboard.addKeys(
        {
          space:       Phaser.Input.Keyboard.KeyCodes.SPACE,
          c:           Phaser.Input.Keyboard.KeyCodes.C,
          toggleSound: Phaser.Input.Keyboard.KeyCodes.T,
        });
    }

    displayHintScreen() {
      if (this.step === 1) {
        this.add.image(0, 0, 'hints').setOrigin(0, 0);
      }

      if (this.step === 2) {
        this.add.image(0, 0, 'hints-2').setOrigin(0, 0);
      }
    }

    startGame(isContinue) {
      if (!isContinue) { this.storedDataManager.clearData(); }

      this.cameras.main.fadeOut(100, 255, 255, 255);

      if (this.step === 1) {
        this.newGameSound.play();
      }

      this.loading = true;

      this.time.addEvent({
          delay: 1000,
          callback: () => {
            if (this.step <= 2) {
              this.displayHintScreen();
              this.cameras.main.fadeIn(100, 255, 255, 255);
              this.loading = false;
              return;
            }

            this.scene.start('PlayScene', {
              storedDataManager: this.storedDataManager,
              continue:          isContinue,
            });
          },
          callbackScope: this,
          loop: false
      });
      this.initialized = true;
    }

    update() {
      if (Phaser.Input.Keyboard.JustDown(this.cursors.toggleSound)) {
        this.toggleBgm();
      }

      if (this.loading) { return; }

      if (Phaser.Input.Keyboard.JustDown(this.cursors.space)) {
        this.step += 1;
        this.startGame(false);
      }

      if (Phaser.Input.Keyboard.JustDown(this.cursors.c) && this.hasContinue) {
        this.step = 3;
        this.startGame(true);
      }
    }
}

export default StartScene;