import GLOBAL_CONFIG from './globalConfig';

class Kson {
    constructor(scene, x, y) {
      this.scene = scene;

      this.jumps = 0;

      this.initPlayer(x, y);
      this.initKeyboard();
      this.initAnimations();

      this.isDead = false;

      this.animationSuffix = '';

      this.dashCooldown = false;
      this.direction = 'RIGHT';
      this.dashing = false;
    }

    initPlayer(x, y) {
      this.sprite = this.scene.physics.add
        .sprite(x, y, 'player')
        .setDrag(GLOBAL_CONFIG.FRICTION, 0)
        .setMaxVelocity(GLOBAL_CONFIG.ACCELERATION, 600)
        .setCollideWorldBounds(true)
        .setSize(30, 85)
        .setOffset(14, 9)
        .setDepth(10);

      this.sprite.setCollideWorldBounds(true);
      this.sprite.setName('player');
      this.isDead = false;
    }

    initKeyboard() {
      //  Input Events
      this.cursors = this.scene.input.keyboard.addKeys(
        {
          up:    Phaser.Input.Keyboard.KeyCodes.UP,
          w:     Phaser.Input.Keyboard.KeyCodes.W,
          left:  Phaser.Input.Keyboard.KeyCodes.LEFT,
          a:     Phaser.Input.Keyboard.KeyCodes.A,
          right: Phaser.Input.Keyboard.KeyCodes.RIGHT,
          d:     Phaser.Input.Keyboard.KeyCodes.D,
          down:  Phaser.Input.Keyboard.KeyCodes.DOWN,
          s:     Phaser.Input.Keyboard.KeyCodes.S,
          space: Phaser.Input.Keyboard.KeyCodes.SPACE,
          one:   Phaser.Input.Keyboard.KeyCodes.ONE,
          two:   Phaser.Input.Keyboard.KeyCodes.TWO,
        });
    }

    initAnimations() {
      const ANIMATION_FRAMERATE = 8;

      this.scene.anims.create({
        key: 'player-idle',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 0, end: 5 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });

      this.scene.anims.create({
        key: 'player-idle-ark',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 20, end: 25 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });

      this.scene.anims.create({
        key: 'player-run',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 6, end: 11 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });

      this.scene.anims.create({
        key: 'player-run-ark',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 26, end: 31 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });

      this.scene.anims.create({
        key: 'player-jump',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 12, end: 18 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });

      this.scene.anims.create({
        key: 'player-jump-ark',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 32, end: 38 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });

      this.scene.anims.create({
        key: 'player-crouch',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 19, end: 19 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });

      this.scene.anims.create({
        key: 'player-crouch-ark',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 39, end: 39 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });

      this.scene.anims.create({
        key: 'player-dash',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 41, end: 41 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });

      this.scene.anims.create({
        key: 'player-dash-ark',
        frames: this.scene.anims.generateFrameNumbers('player', { start: 40, end: 40 }),
        frameRate: ANIMATION_FRAMERATE,
        repeat: -1,
      });
    }

    restoreSize() {
      this.sprite
          .setSize(30, 85)
          .setOffset(14, 9);
    }

    update() {
      if (this.isDead) { return; }

      const onGround = this.sprite.body.blocked.down;
      const acceleration = onGround ? GLOBAL_CONFIG.ACCELERATION : GLOBAL_CONFIG.AIR_ACCELERATION;

      if (Phaser.Input.Keyboard.JustDown(this.cursors.one)) {
        this.animationSuffix = '';
      }

      if (Phaser.Input.Keyboard.JustDown(this.cursors.two)) {
        this.animationSuffix = '-ark';
      }

      if ((this.cursors.down.isDown || this.cursors.s.isDown) && onGround) {
        this.sprite.anims.play(`player-crouch${this.animationSuffix}`, true);
        this.sprite
          .setSize(30, 60)
          .setOffset(14, 35);
      } else if (this.cursors.left.isDown || this.cursors.a.isDown) {
        this.direction = 'LEFT';
        this.sprite.setVelocityX(-acceleration);
        if (onGround) {
          this.sprite.anims.play(`player-run${this.animationSuffix}`, true);
        } else {
          this.sprite.anims.play(`player-jump${this.animationSuffix}`, true);
        }

        if (this.dashing) {
          this.sprite.anims.play(`player-dash${this.animationSuffix}`, true);
        }

        this.sprite.setFlip(true, 0);

        this.restoreSize();
      } else if (this.cursors.right.isDown || this.cursors.d.isDown) {
        this.direction = 'RIGHT';
        this.sprite.setVelocityX(acceleration);

        if (onGround) {
          this.sprite.anims.play(`player-run${this.animationSuffix}`, true);
        } else {
          this.sprite.anims.play(`player-jump${this.animationSuffix}`, true);
        }

        if (this.dashing) {
          this.sprite.anims.play(`player-dash${this.animationSuffix}`, true);
        }

        this.sprite.setFlip(false, 0);

        this.restoreSize();
      } else {
        if (onGround) {
          this.sprite.anims.play(`player-idle${this.animationSuffix}`, true);
        } else {
          this.sprite.anims.play(`player-jump${this.animationSuffix}`, true);
        }

        if (this.dashing) {
          this.sprite.anims.play(`player-dash${this.animationSuffix}`, true);
        }

        this.restoreSize();
      }

      if (onGround) {
        this.jumps = 0;
      }

      const DASH_DISTANCE = 130;
      const DASH_TIME = 100;

      if (Phaser.Input.Keyboard.JustDown(this.cursors.space) && !this.dashCooldown) {
        this.dashCooldown = true;
        this.dashing = true;

        this.scene.time.addEvent({
          delay: 500,
          callback: () => {
            this.dashCooldown = false;
          },
          callbackScope: this,
          loop: false
        });

        this.scene.add.tween({
          targets: this.sprite,
          ease: 'Sine.easeIn',
          duration: DASH_TIME,
          delay: 0,
          loop: 0,
          x: {
            getStart: () => this.sprite.x,
            getEnd: () => (this.direction === 'RIGHT') ? this.sprite.x + DASH_DISTANCE : this.sprite.x - DASH_DISTANCE,
          },
          onComplete: () => { this.dashing = false; }
        });
      }

      if ((Phaser.Input.Keyboard.JustDown(this.cursors.up) || Phaser.Input.Keyboard.JustDown(this.cursors.w)) && this.jumps < GLOBAL_CONFIG.MAX_JUMPS) {
        this.sprite.setVelocityY(-GLOBAL_CONFIG.JUMP_HEIGHT);
        this.jumps += 1;

        if (this.scene.jumpSound && this.jumps === 1) { this.scene.jumpSound.play(); }
        if (this.scene.jumpSound3 && this.jumps > 1) { this.scene.jumpSound3.play(); }

      }

      if ((Phaser.Input.Keyboard.JustDown(this.cursors.down) || Phaser.Input.Keyboard.JustDown(this.cursors.s)) && !onGround) {
        this.sprite.setVelocityY(GLOBAL_CONFIG.JUMP_HEIGHT);
      }

    }

    destroy() {
      this.sprite.destroy();
    }
}

export default Kson;